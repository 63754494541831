<template>
  <div id="project-files" class="ma-4">
    <div class="d-flex justify-space-between mb-6">
      <div class="d-flex">
        <v-btn
          class="mt-5 ml-2 mr-2 m_brown"
          icon
          @click="$router.back()"
          :disabled="campaign.id === ''"
        >
          <v-icon large>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <div>
          <h1>{{ $cap(campaign.name) }}</h1>
          <i>Livrables</i>
        </div>
      </div>
    </div>

    <div class="pl-2">
      <FileManager></FileManager>
    </div>

    <CreateDownloadLinkModale
      :files="download.selected"
      @newLink="displayNewLink"
      v-if="download.showDownloadModale"
      @closePopin="download.showDownloadModale = false"
    />
    <v-snackbar vertical timeout="8000" v-model="linkSnackbar.shouldDisplay">
      <p v-if="!linkSnackbar.copied">
        Lien de téléchargement créé ! <br />
        <a ref="downloadLink" :href="linkSnackbar.link">{{
          linkSnackbar.link
        }}</a>
      </p>
      <p v-else>Lien copié dans le presse papier !</p>

      <template v-slot:action="{ attrs }">
        <MediumButtonSlot
          v-if="!linkSnackbar.copied"
          v-bind="attrs"
          @click="copyTextToClipboard()"
          >Copier</MediumButtonSlot
        >
        <MediumButtonSlot
          v-else
          v-bind="attrs"
          @click="linkSnackbar.shouldDisplay = false"
          >Fermer</MediumButtonSlot
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import CreateDownloadLinkModale from "@/components/entities/file/CreateDownloadLink.modal.vue";
import FileManager from "../../components/campaign/FileManager.vue";

export default {
  name: "ProjectFiles",
  components: {
    CreateDownloadLinkModale,
    FileManager
  },
  data() {
    return {
      linkSnackbar: {
        shouldDisplay: false,
        link: "",
        copied: false
      },
      isMergeOptionActive: false,
      isGenerateLinkOptionActive: false,
      loading: true,
      campaign: {},
      search: "",
      isSelecting: false,
      download: {
        selected: [],
        showDownloadModale: false
      },
      documentFiles: [],
      isDocumentUploading: false,
      headers: [],
      documents: [],
      numberedDocuments: [],
      rules: [v => !!v || "Une valeur est requise"]
    };
  },
  watch: {
    isSelecting() {
      if (this.isSelecting === false) this.download.selected = [];
    },
    // reset numbers on documents change
    documents() {
      this.numberedDocuments = this.documents.map(d => {
        return { ...d, number: 0 };
      });
    },
    "download.selected": {
      deep: true,
      handler(newSelected, oldSelected) {
        if (newSelected.length < oldSelected.length) this.handleRemove();
        else {
          let newId = 0;

          for (let i = 0; i < newSelected.length; i++) {
            const elem = newSelected[i];
            if (-1 === oldSelected.findIndex(d => d.id === elem.id))
              newId = elem.id;
          }

          this.handleNewSelection(newId);
        }
      }
    }
  },
  computed: {
    tableElements() {
      return this.isMergeOptionActive
        ? this.numberedDocuments.filter(d => "pdf" === d.extension)
        : this.numberedDocuments;
    },
    tableHeaders() {
      let headers = [
        { text: "Nom", value: "name" },
        { text: "Date de création", value: "createdAt" },
        { text: "Taille du fichier", value: "size" },
        { text: "Télécharger", value: "download" }
      ];

      if (this.isSelecting && this.isMergeOptionActive)
        headers.splice(0, 0, { text: "Ordre (PDF fusionné)", value: "number" });

      return headers;
    }
  },
  mounted() {
    this.getCampaign();
  },
  methods: {
    isSelected(documentId) {
      return -1 !== this.download.selected.findIndex(d => d.id === documentId);
    },
    handleNewSelection(newId) {
      // on new selection, number to attribute is current last number + 1
      let max = 0;
      for (let i = 0; i < this.download.selected.length; i++) {
        const document = this.download.selected[i];
        if (document.number > max) max = document.number;
      }

      const doc = this.numberedDocuments.find(d => d.id === newId);

      if (undefined !== doc) doc.number = max + 1;
    },
    handleRemove() {
      let selected = [...this.download.selected];
      selected.sort((a, b) => a.number - b.number);

      for (let i = 0; i < selected.length; i++) {
        let current = selected[i];

        if (i + 1 !== current.number) current.number = i + 1;
      }

      for (let i = 0; i < this.numberedDocuments.length; i++) {
        const doc = this.numberedDocuments[i];
        if (false === this.isSelected(doc.id)) doc.number = 0;
      }
    },
    async getCampaign() {
      this.campaign = await this.$api.campaigns.findById(this.$route.params.id);
    },
    displayNewLink(link) {
      this.linkSnackbar.copied = false;
      this.linkSnackbar.link = link;
      this.linkSnackbar.shouldDisplay = true;
      this.download.showDownloadModale = false;
    },
    fallbackCopyTextToClipboard() {
      this.$refs.downloadLink.focus();
      this.$refs.downloadLink.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
        this.linkSnackbar.copied = true;
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
    }
  }
};
</script>

<style lang="scss">
#project-files {
  height: 100%;
}

.rotating-icon {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.v-data-footer {
  &__select {
    visibility: hidden !important;
  }
  &__pagination {
    visibility: hidden !important;
  }
}

.bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
