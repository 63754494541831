<template>
	<div class="tw-flex tw-flex-col tw-gap-[15px]">
		<CreateDownloadLinkModale
		style="z-index: 20"
		:files="selectedFiles"
		v-if="showDownloadLinkModale"
		@closePopin="showDownloadLinkModale = false"
		/>

		<v-dialog
		v-model="showAddDocument"
		width="800"
		>
			<v-card>
				<v-card-title> Ajouter de nouveaux documents </v-card-title>

				<v-card-text>
					Le document sera ajouté à cet emplacement :
					<v-chip>{{ folder }}</v-chip>
				</v-card-text>

				<v-form
				ref="sendDocumentForm"
				@submit.prevent="sendDocument()"
				>
					<v-card-text>
						<v-file-input
						v-model="documentFiles"
						label="Ajoutez un ou plusieurs fichiers"
						show-size
						truncate-length="15"
						:rules="rules"
						multiple
						/>
					</v-card-text>

					<v-divider/>

					<v-card-actions>
						<medium-button-slot
						color="primary"
						text
						@click="showAddDocument = false"
						>
							Annuler
						</medium-button-slot>

						<v-spacer/>

						<HighButtonSlot
						:_disabled="isDocumentUploading || documentFiles.length === 0"
						:_type="'submit'"
						>
							<v-icon
							class="rotating-icon"
							v-if="isDocumentUploading"
							>
								mdi-refresh
							</v-icon>
							Envoyer
						</HighButtonSlot>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<section class="tw-flex tw-justify-between">
			<div class="tw-flex tw-gap-[10px]">
				<ButtonSlot
				v-if="$hasRight('files.uploadDeliverables')"
				@click="showAddDocument = true"
				>
					Ajouter des documents
				</ButtonSlot>

				<ButtonSlot
				@click="exportAllCores()"
				:_loading="zipLoading"
				:_disabled="zipLoading"
				_theme="light-gray"
				v-if="campaign.type === 'coring'"
				>
					Exporter les carottes
				</ButtonSlot>
			</div>

			<ButtonSlot
			v-if="
				$hasRight('files.uploadDeliverables') && campaign.type === 'coring'
			"
			style="float: right"
			@click="regeneratePdf()"
			:_disabled="pdfIsGenerating"
			>
				Regénérer les PDF
			</ButtonSlot>
		</section>

		<v-alert
		dense
		type="info"
		v-if="pdfIsGenerating"
		>
			Vos documents sont en cours de génération, cette opération peut durer
			plusieurs minutes. Merci de patienter.
		</v-alert>

		<v-card
		class="content"
		outlined
		>
			<v-row>
				<v-col
				cols="12"
				sm="6"
				md="6"
				>
					<v-form @submit.prevent="search()">
						<v-text-field
						filled
						v-model="searchQuery"
						rounded
						label="Rechercher un dossier ou un fichier..."
						required
						append-icon="mdi-magnify"
						dense
						clearable
						/>
					</v-form>

					<HighButtonSlot
					v-if="
						showSelection === true &&
							$hasRight('files.generateDeliverablesShareLink')
					"
					:_disabled="selectedFiles.length === 0"
					style="margin-right: 10px; margin-bottom: 10px"
					@click="showDownloadLinkModale = true"
					>
						Générer un lien de partage
					</HighButtonSlot>

					<HighButtonSlot
					v-if="showSelection === true"
					:_disabled="selectedFiles.length === 0"
					style="margin-right: 10px; margin-bottom: 10px"
					@click="zipSelectedFiles()"
					>
						Créer un fichier zip
					</HighButtonSlot>

					<HighButtonSlot
					v-if="showSelection === true"
					:_disabled="selectedFiles.length < 2 || pdfBtnDisabled === true"
					style="margin-bottom: 10px"
					@click="mergePdf()"
					>
						Fusionner les PDF
					</HighButtonSlot>
				</v-col>

				<v-col
				cols="12"
				sm="6"
				md="6"
				>
					<MediumButtonSlot
					style="float: right; margin-top: 10px"
					@click="createFolderDialog = true"
					>
						Créer un dossier
					</MediumButtonSlot>

					<MediumButtonSlot
					style="float: right; margin-top: 10px; margin-right: 10px"
					@click="() => {
						showSelection ? unselectAllFile() : null;
						showSelection = !showSelection;
					}"
					v-if="getOnlyRealFiles() > 0"
					>
						{{ showSelection ? "Annuler" : "Sélectionner" }}
					</MediumButtonSlot>

					<MediumButtonSlot
					style="float: right; margin-top: 10px; margin-right: 10px"
					v-if="true === showSelection"
					@click="selectAllFile()"
					>
						Sélectionner tout
					</MediumButtonSlot>

					<MediumButtonSlot
					style="float: right; margin-top: 10px; margin-right: 10px"
					v-if="selectedFilesIndexes.length > 0"
					@click="unselectAllFile()"
					>
						Désélectionner tout
					</MediumButtonSlot>
				</v-col>
			</v-row>

			<v-card
			outlined
			class="breadcrumbs"
			>
				<v-btn
				@click="sort()"
				text
				class="sort_btn"
				>
					<v-icon
					left
					v-if="sorting === 'ASC'"
					>
						mdi-sort-alphabetical-ascending
					</v-icon>

					<v-icon
					left
					v-else
					>
						mdi-sort-alphabetical-descending
					</v-icon>
					Trier
				</v-btn>

				<v-breadcrumbs
				:items="breadcrumbs"
				style="display: inline-block"
				>
					<template v-slot:divider>
						<v-icon>mdi-chevron-right</v-icon>
					</template>

					<template v-slot:item="{ item }">
						<v-breadcrumbs-item
						class="breadcrumb_item"
						@click="navigate(item.index)"
						:disabled="item.disabled"
						>
							{{ item.text }}
						</v-breadcrumbs-item>
					</template>
				</v-breadcrumbs>
			</v-card>

			<div
			v-if="files.length === 0"
			class="text-center"
			>
				<div
				class="folder"
				style="margin-bottom: 10px"
				>
					<v-icon>mdi-folder</v-icon>
				</div>

				<h2>Dossier vide, importez des fichiers</h2>
			</div>

			<div class="folders d-flex justify-start mb-6 flex-wrap">
				<div
				class="folder"
				v-for="(file, index) in files"
				:key="index"
				>
					<div
					v-if="isFolder(file) === false || searchMode === true"
					class="relative_parent"
					>
						<v-checkbox
						v-if="showSelection"
						:value="file.id"
						v-model="selectedFilesIndexes"
						class="file_checkbox"
						hide-details
						/>

						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<div @click="if (showSelection === false) downloadFile(file);">
									<v-icon
									v-bind="attrs"
									v-on="on"
									>
										{{
											resolveIcon(file.extension)
										}}
									</v-icon>

									<h1>{{ file.name }}</h1>
								</div>
							</template>

							<span>
								Taille :
								{{ file.size === null ? "0 Byte" : bytesToSize(file.size) }}
							</span>
						</v-tooltip>
					</div>

					<div
					v-else
					@click="changeFolder(file.folder, isFolder(file))"
					>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
								v-bind="attrs"
								v-on="on"
								>
									mdi-folder
								</v-icon>

								<h1>{{ isFolder(file) }}</h1>
							</template>

							<span>
								Taille :
								{{ file.size === null ? "0 Byte" : bytesToSize(file.size) }}
							</span>
						</v-tooltip>
					</div>
				</div>
			</div>
		</v-card>

		<v-dialog
		v-model="createFolderDialog"
		width="500"
		>
			<v-card class="pa-4">
				<v-card-text style="padding-top: 15px; padding-bottom: 0">
					<v-text-field
					label="Nom du dossier"
					required
					clearable
					v-model="newFolderName"
					/>
				</v-card-text>

				<v-card-actions>
					<medium-button-slot
					color="primary"
					text
					@click="createFolderDialog = false"
					>
						Annuler
					</medium-button-slot>

					<v-spacer/>

					<HighButtonSlot
					text
					@click="createFolder()"
					>
						Créer
					</HighButtonSlot>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-card
		v-if="selectedFiles.length !== 0 && selectionModaleReduced === false"
		class="selection_menu"
		elevation="2"
		>
			<v-list>
				<v-subheader>
					Votre sélection ({{ selectedFiles.length }} fichier(s))
					<v-btn
					@click="selectionModaleReduced = true"
					icon
					style="position: absolute; right: 20px"
					>
						<v-icon>mdi-minus</v-icon>
					</v-btn>
				</v-subheader>

				<v-list-item-group color="primary">
					<v-list-item
					v-for="(file, i) in selectedFiles"
					:key="i"
					>
						<v-list-item-icon>
							<v-icon>{{ resolveIcon(file.extension) }}</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title v-text="file.name"/>
						</v-list-item-content>

						<v-list-item-action>
							<v-btn
							@click="moveUp(i)"
							icon
							v-if="i !== 0"
							>
								<v-icon color="grey lighten-1">
									mdi-arrow-up
								</v-icon>
							</v-btn>
						</v-list-item-action>

						<v-list-item-action>
							<v-btn
							@click="moveDown(i)"
							icon
							v-if="i !== selectedFiles.length - 1"
							>
								<v-icon color="grey lighten-1">
									mdi-arrow-down
								</v-icon>
							</v-btn>
						</v-list-item-action>

						<v-list-item-action>
							<v-btn
							@click="unselectFile(file.id)"
							icon
							>
								<v-icon color="red lighten-1">
									mdi-close
								</v-icon>
							</v-btn>
						</v-list-item-action>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-card>

		<v-badge
		v-if="selectedFiles.length !== 0 && selectionModaleReduced === true"
		class="selection_btn"
		avatar
		bordered
		overlap
		>
			<template v-slot:badge>
				<v-avatar>
					{{ selectedFiles.length }}
				</v-avatar>
			</template>

			<v-avatar size="70">
				<v-btn
				@click="selectionModaleReduced = false"
				fab
				large
				elevation="2"
				>
					<v-icon> mdi-selection </v-icon>
				</v-btn>
			</v-avatar>
		</v-badge>
	</div>
</template>

<script>
import CreateDownloadLinkModale from "@/components/entities/file/CreateDownloadLink.modal.vue";

export default {
	name: "FileManager",
	components: {
		CreateDownloadLinkModale
	},
	data(){
		return {
			createFolderDialog: false,
			newFolderName: null,
			currentFolder: "Home",
			files: [],
			cachedFiles: [],
			selectedFiles: [],
			selectedFilesIndexes: [],
			showSelection: false,
			showShareValidationBtn: false,
			showDownloadLinkModale: false,
			sorting: "ASC",
			selectionModaleReduced: false,
			showAddDocument: false,
			lastBreadcrumbsIndex: 0,
			pdfBtnDisabled: false,
			documentFiles: [],
			isDocumentUploading: false,
			zipLoading: false,
			breadcrumbs: [
				{
					text: "Racine",
					index: "/"
				}
			],
			searchQuery: null,
			searchMode: false,
			rules: [v => !!v || "Une valeur est requise"],
			campaign: {},
			pdfIsGenerating: false
		};
	},
	computed: {
		folder(){
			if(this.$route.query.path == undefined){
				return "Racine";
			}
			else {
				return "Racine/" + this.$route.query.path;
			}
		}
	},
	watch: {
		searchQuery(value){
			if(null === value || value === ""){
				this.searchMode = false;
				this.getFiles(this.$route.query.path, false, this.sorting);
			}
		},
		selectedFilesIndexes(value){
			if(value === null){
				return;
			}

			this.selectedFiles = [];

			this.cachedFiles.forEach(file => {
				if(value.includes(file.id) === true){
					this.selectedFiles.push(file);
				}
			});

			let onlyPdf = true;

			this.selectedFiles.forEach(file => {
				if(file.extension !== "pdf"){
					onlyPdf = false;
				}
			});

			if(onlyPdf === false){
				this.pdfBtnDisabled = true;
			}
			else {
				this.pdfBtnDisabled = false;
			}
		}
	},
	methods: {
		moveUp(i){
			let numberOfDeletedElm = 1;
			const elm = this.selectedFiles.splice(i, numberOfDeletedElm)[0];
			numberOfDeletedElm = 0;
			this.selectedFiles.splice(i - 1, numberOfDeletedElm, elm);
		},
		moveDown(i){
			let numberOfDeletedElm = 1;
			const elm = this.selectedFiles.splice(i, numberOfDeletedElm)[0];
			numberOfDeletedElm = 0;
			this.selectedFiles.splice(i + 1, numberOfDeletedElm, elm);
		},
		getOnlyRealFiles(){
			return this.files.filter(file => file.s3Path !== null).length;
		},
		async getFiles(folder, reloadCache = false, order = null, search = null){
			let files = [];
			files = await this.$api.documents.findByCampaign(
				this.$route.params.id,
				"delivery",
				folder,
				order,
				search
			);

			let sanitizedFiles = [];
			let existingFolders = [];

			files.forEach(element => {
				if(element.folder !== null && this.$route.query.path == undefined){
					let splittedFolder = element.folder.split("/");
					let folder = splittedFolder[0];

					if(existingFolders.includes(folder) === false){
						sanitizedFiles.push(element);
					}
					existingFolders.push(folder);
				}

				if(element.folder !== null && element.folder.includes("/")){
					let rebuildedFolder = "";
					let splittedFolder = element.folder.split("/");

					splittedFolder.some((folder, index) => {
						if(splittedFolder.length === index + 1){
							rebuildedFolder += folder;
						}
						else {
							rebuildedFolder += folder + "/";
						}

						if(rebuildedFolder === this.$route.query.path + "/"){
							if(existingFolders.includes(rebuildedFolder) === false){
								sanitizedFiles.push(element);
							}
							existingFolders.push(rebuildedFolder);
						}
					});
				}

				if(
					(element.folder === null ||
            element.folder === this.$route.query.path) &&
          element.s3Path !== null
				){
					sanitizedFiles.push(element);
				}
			});

			this.files = sanitizedFiles;

			if(reloadCache === true){
				this.cachedFiles = files;
			}
		},
		async mergePdf(){
			const documentsIds = this.selectedFiles.map(doc => doc.id);
			const pdf = await this.$api.documents.getMergedPDF(documentsIds);
			const newBlob = new Blob([pdf], {type: "application/pdf"});
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");

			link.href = data;
			link.download = "PDF fusionné";
			window.open(link, "_blank");
		},
		async exportAllCores(){
			this.zipLoading = true;
			const zip = await this.$api.documents.getAllCoresAsPdf(
				this.$route.params.id
			);
			const newBlob = new Blob([zip], {type: "application/zip"});
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");

			link.href = data;
			link.download = "Exportation des carottes";
			this.zipLoading = false;
			window.open(link, "_blank");
		},
		regeneratePdf(){
			this.pdfIsGenerating = true;
			this.$api.coringCampaigns
			.getFromProvider(this.$route.params.id, true)
			.then(() => {
				this.getFiles(this.$route.query.path);
			});
		},
		search(){
			this.getFiles(
				this.$route.query.path,
				false,
				this.sorting,
				this.searchQuery
			);
			this.searchMode = true;
		},
		sort(){
			if(this.sorting === "ASC"){
				this.sorting = "DESC";
			}
			else {
				this.sorting = "ASC";
			}
			this.getFiles(this.$route.query.path, false, this.sorting);
		},
		unselectFile(fileId){
			this.selectedFilesIndexes.some((id, key) => {
				if(id === fileId){
					this.selectedFilesIndexes.splice(key, 1);
				}
				return;
			});
		},
		selectAllFile(){
			this.files.forEach(file => {
				if(file.s3Path !== null) this.selectedFilesIndexes.push(file.id);
			});
		},
		unselectAllFile(){
			this.selectedFilesIndexes = [];
		},
		zipSelectedFiles(){
			this.$api.documents
			.zipSelected(this.selectedFilesIndexes)
			.then(zipContent => {
				const url = window.URL.createObjectURL(new Blob([zipContent]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "livrables.zip");
				link.click();
			});
		},
		downloadFile(file){
			this.$api.documents.download(file.s3Path).then(documentContent => {
				const url = window.URL.createObjectURL(new Blob([documentContent]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", file.name);
				link.click();
			});
		},
		sendDocument(){
			if(this.$refs.sendDocumentForm.validate() === false){
				return;
			}
			this.isDocumentUploading = true;

			var re = /(?:\.([^.]+))?$/;

			this.documentFiles.forEach(documentFile => {
				var formData = new FormData();
				formData.append("file", documentFile);
				formData.append("campaignId", this.$route.params.id);
				formData.append("category", "delivery");

				var extension = re.exec(documentFile.name)[1];

				this.$api.documents
				.upload(formData, {
					name: documentFile.name,
					extension: extension,
					size: documentFile.size,
					category: "delivery",
					campaignId: this.$route.params.id,
					requiredDocumentId: null,
					folder: this.$route.query.path
				})
				.then(document => {
					this.showAddDocument = false;
					this.files.push(document);
					this.isDocumentUploading = false;
					this.documentFiles = [];
				})
				.catch(() => (this.isDocumentUploading = false));
			});
		},
		isFolder(file){
			if(this.$route.query.path == undefined && file.folder === null){
				return false;
			}

			const path = this.$route.query.path;

			if(file.folder === path){
				return false;
			}

			if(file.folder === null){
				return file.name;
			}

			const splittedFolder = file.folder.split("/");

			if(this.$route.query.path == undefined){
				return splittedFolder[0];
			}

			let rebuildedPath = "";
			let folderName = null;

			splittedFolder.forEach((element, index) => {
				if(splittedFolder.length === index + 1){
					rebuildedPath += element;
				}
				else {
					rebuildedPath += element + "/";
				}

				if(rebuildedPath === path + "/"){
					if(splittedFolder.length === index){
						folderName = false;
						return;
					}
					folderName = splittedFolder[index + 1];
					return;
				}
			});
			return folderName;
		},
		resolveIcon(extension){
			switch (extension){
				case "pdf":
					return "mdi-file-pdf-box";
				case "xls":
				case "xlsx":
					return "mdi-microsoft-excel";
				case "doc":
				case "docx":
					return "mdi-microsoft-word";
				case "jpg":
				case "gif":
				case "jpeg":
				case "png":
					return "mdi-file-image";
				case "zip":
				case "7zip":
					return "mdi-folder-zip";
				default:
					return "mdi-file";
			}
		},
		createFolder(){
			let currentPath = "/";

			if(this.$route.query.path != undefined){
				currentPath = this.$route.query.path;
			}
			this.$api.documents
			.create({
				campaignId: this.campaign.id,
				folder:
            currentPath === "/"
            	? this.newFolderName
            	: currentPath + "/" + this.newFolderName,
				category: "delivery"
			})
			.then(data => {
				this.files.push(data);
			});

			//this.changeFolder(currentPath, this.newFolderName, false, false);

			/*if (this.$route.query.path != undefined) {
        this.$router.replace({
          name: "campaign-deliverables",
          query: { path: currentPath + "/" + this.newFolderName }
        });
      } else {
        this.$router.replace({
          name: "campaign-deliverables",
          query: { path: this.newFolderName }
        });
      }*/

			//this.files.push();
			this.createFolderDialog = false;
			this.newFolderName = null;
		},
		navigate(index){
			this.files = [];

			if(index === "/"){
				this.$router.push({
					name: "campaign-deliverables"
				});
				this.getFiles(null);
				this.breadcrumbs = [
					{
						text: "Racine",
						index: "/"
					}
				];
				return;
			}

			let newBreadcrumbs = [];
			let newPath = "";

			this.breadcrumbs.some((element, key) => {
				if(element.index > index){
					return;
				}

				if(key !== 0){
					newPath += element.text + "/";
				}

				if(element.index === index){
					element.disabled = true;
				}

				newBreadcrumbs.push(element);
			});

			this.breadcrumbs = newBreadcrumbs;

			this.getFiles(newPath.slice(0, -1));

			this.$router.replace({
				name: "campaign-deliverables",
				query: {path: newPath.slice(0, -1)}
			});
		},
		changeFolder(folderPath, targetFolder, getFiles = true, redirect = true){
			this.files = [];
			const splittedFolder = folderPath.split("/");
			let newPath = "";
			// Some trick due to the fucking shitty logic of js
			let newFuckingPath = null;

			if(this.$route.query.path == undefined){
				newPath = splittedFolder[0];
			}
			else {
				splittedFolder.some((element, index) => {
					if(splittedFolder.length === index + 1){
						newPath += element;
					}
					else {
						newPath += element + "/";
					}

					if(element === targetFolder){
						newFuckingPath = newPath;
					}
				});
			}

			if(newFuckingPath !== null){
				if("/" === newFuckingPath.slice(-1)){
					newPath = newFuckingPath.slice(0, -1);
				}
				else {
					newPath = newFuckingPath;
				}
			}

			if(getFiles === true){
				this.getFiles(newPath);
			}

			this.breadcrumbs = this.breadcrumbs.map(element => {
				element.disabled = false;
				return element;
			});

			(this.lastBreadcrumbsIndex = this.lastBreadcrumbsIndex + 1),
			this.breadcrumbs.push({
				text: targetFolder,
				disabled: true,
				index: this.lastBreadcrumbsIndex
			});

			if(true === redirect){
				this.$router.replace({
					name: "campaign-deliverables",
					query: {path: newPath}
				});
			}
		},
		bytesToSize(bytes){
			var sizes = [
				"Bytes", "KB", "MB", "GB", "TB"
			];
			if(bytes == 0) return "0 Byte";
			var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
			return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
		}
	},
	mounted(){
		this.$api.campaigns
		.findById(this.$route.params.id)
		.then(data => {
			this.campaign = data;
		})
		.then(() => {
			this.getFiles(this.$route.query.path, true).then(() => {
				if(
					(this.campaign.status !== "awaiting_validation" ||
              this.campaign.status !== "programing_validated") &&
            this.campaign.type === "coring"
				){
					this.files.push({
						name: this.campaign.name + ".zip",
						size: 12333342,
						extension: "zip",
						s3Path: "[[all_pictures]]_" + this.$route.params.id
					});
				}
			});
		});

		addEventListener("popstate", () => {
			window.location.reload();
		});

		if(this.$route.query.path == undefined){
			return;
		}

		const path = this.$route.query.path;
		const splittedFolder = path.split("/");
		let disabledBreacrumbs = false;

		splittedFolder.forEach((element, index) => {
			if(index + 1 === splittedFolder.length){
				disabledBreacrumbs = true;
			}

			this.breadcrumbs.push({
				text: element,
				disabled: disabledBreacrumbs,
				index
			});

			this.lastBreadcrumbsIndex = index;
		});
	}
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px;
  max-height: 80vh;
}

.selection_menu {
  position: absolute;
  bottom: 10px;
  right: 10px;
  min-width: 400px;
  z-index: 11;
  max-height: 60vh;
  overflow-y: auto;
}

.selection_btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
}

.breadcrumb_item {
  cursor: pointer;
}

.file_checkbox {
  position: absolute;
  margin-top: 20px;
  margin-left: 8px;
  z-index: 10;
}

.relative_parent {
  position: relative;
}

.breadcrumbs {
  margin-bottom: 30px;
}
.folder * {
  width: 150px;
  cursor: pointer;
  text-align: center;
}

.folders {
  overflow-y: auto;
  overflow-x: hidden;
  height: 50vh;
}

h1 {
  font-size: 15px;
}

.folder i {
  font-size: 70px !important;
}

.sort_btn {
  display: inline-block;
  text-transform: none;
  margin-left: 5px;
}
</style>
